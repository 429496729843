<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학자재 별 MSDS 목록"
      tableId="riskHazrd01"
      :editable="editable"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name === 'warning'">
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            size="12px"
            style="font-weight:700"
            label="6 x 4"
            @click="linkClick(props.row, col, 's')" />
          <q-btn 
            dense
            outline 
            icon="download"
            color="blue-9"
            size="12px"
            style="font-weight:700"
            label="11 x 9"
            @click="linkClick(props.row, col, 'm')" />
          <q-btn 
            dense
            outline 
            icon="check"
            color="green-9"
            size="12px"
            style="font-weight:700"
            label="요약본"
            @click="linkClick(props.row, col, 'SUMMARY')" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-manage',
  data() {
    return {
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [],
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemMaterialId' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
          { index: 3, colName: 'mdmChemMaterialId' },
          { index: 4, colName: 'mdmChemMaterialId' },
          // { index: 5, colName: 'mdmChemMaterialId' },
          { index: 7, colName: 'mdmChemMaterialId' },
          { index: 8, colName: 'mdmChemMaterialId' },
          // { index: 9, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장명',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'mfgVendorName',
            field: 'mfgVendorName',
            label: '제조사',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'warning',
            field: 'warning',
            label: '경고표지',
            align: 'center',
            style: 'width:90px',
            sortable: true,
            type: 'custom'
          },
          // {
          //   name: 'msds',
          //   field: 'msds',
          //   label: 'MSDS<br/>요약본',
          //   align: 'center',
          //   style: 'width:90px',
          //   sortable: true,
          //   type: 'custom'
          // },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '구성물질',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS NO.',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'col7',
            field: 'col7',
            label: '내부 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS',
            keyText: 'mdmChemMaterialMsdsId',
            sortable: false,
          },
          {
            name: 'col8',
            field: 'col8',
            label: '업체 MSDS',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'CHEM_MSDS_VENDOR',
            keyText: 'mdmChemMaterialMsdsId',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.msds.url;
      this.printUrl = selectConfig.mdm.mam.chem.print.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row, col, size) {
      if (col.name === 'materialName') {
        // 화학자재 상세 페이지 열기
        this.$refs['table'].uploadResearchSetting(row, ['col7', 'col8']);
        this.popupOptions.title = '화학자재 상세';
        this.popupOptions.param = {
          mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
          plantCd: row ? row.plantCd : '',
          tabName: 'chemEtc',
        };
        this.popupOptions.target = () => import(`${'./chemDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'warning') {
        if (size == 'SUMMARY') {
          this.popupOptions.title = 'MSDS 요약정보';
          this.popupOptions.param = {
            mdmChemMaterialId: row ? row.mdmChemMaterialId : '',
          };
          this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chemSummary.vue'}`);
          this.popupOptions.visible = true;
          this.popupOptions.isFull = true;
          this.popupOptions.closeCallback = this.closePopup;
        } else {
          // 경고표지 출력
          let thisVue = this;
          this.$http.url = this.$format(this.printUrl, row.mdmChemMaterialId);
          this.$http.type = 'GET';
          this.$http.param = {
            size: size
          };
          this.$http.request(
            _result => {
              let sizeTitle = size === 's' ? '6 x 4' : '11 x 9';
              let fileOrgNm = row.materialName + '_' + sizeTitle + '.xlsx';
              let blob = thisVue.$comm.base64ToBlob(_result.data);
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
              } else {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileOrgNm;
                link.click();
              }
            }, () => { }
          );
        }
      } 
    },
    closePopup() {
      this.$refs['table'].uploadResearch();
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
